<template>

  <div class="container">
    <Navbar v-bind:title="'Słownik'"></Navbar>
    <template v-if="wordData">
    <div class="row">
      <div class="col-md-12">
        <div class="card fm-card">
          <i class="bi bi-x text-end fm-card-cancel-button" v-on:click="$router.go(-1)"></i>
          <img v-bind:src="wordData.originalLanguage.image" class="card-img-top img-thumbnail fm-card-image" alt="...">
          <div class="row align-items-center">
            <div class="col-10">
              <div class="card-body text-start">
                <h5 class="card-title fm-word-original-language">{{ wordData.originalLanguage.word }}</h5>
                <p class="card-text fm-translation-language">{{ wordData.translationLanguage.word }}</p>
              </div>
            </div>
            <div class="col-2">
              <i class="bi bi-volume-down-fill text-center" style="font-size: 2rem" v-on:click="playAudio(wordData.originalLanguage.sound)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  </div>
<!--  JOB END: Rozkminianie struktury page'y i view dla słownika - lista wyrazów i szczegóły wyrazu-->

</template>

<script>
import Navbar from '../../components/Navbar';
import dictionaryService from '../../services/dictionaryService';

export default {
  name: 'WordDetails',
  components: { Navbar },
  data() {
    return {
      wordId: null,
      wordData: null
    };
  },
  mounted() {
    const routeParams = this.$route.params;
    this.wordId = routeParams.id ?? null;
    this.displayWordData(this.wordId);
  },
  methods: {
    // async initializeView() {
    //   await this.displayWordData();
    // },
    async displayWordData(wordId) {
      const response = await dictionaryService.getWord(wordId);
      const word = response.data.words.items[0];

      if (word) {
        this.wordData = word;
        this.sound = word.originalLanguage.sound;
        const wordAudio = document.getElementById('word-audio');
        if (wordAudio) {
          wordAudio.load();
        }
      } else {
        window.alert('No letters in response');
      }
    },
    playAudio(soundUrl) {
      const audio = new Audio(soundUrl);
      audio.play();
    }
  }
};
</script>

<style scoped>
.fm-word-original-language {
  font-weight: bold;
  color: var(--navy-blue);
  font-size: 1.25rem;
}

.fm-translation-language {
  font-size: 1rem;
}

.fm-card {
  margin-top: 1rem;
}

.fm-card-image {
  /*margin-top: 1.75rem;*/
  padding: 0.25rem;
  border-left: 0 solid;
  border-right: 0 solid;
  /*border: 1px solid rgba(0,0,0,.125);*/
}

.fm-card-cancel-button {
  font-size: 1.9rem;
  color: gray;
}
</style>
